.hover-pop-effect:hover {
  transform: translateY(-3px);
  box-shadow: 0 4px 8px rgba(27, 167, 232, 0.2);
  transition: transform 0.1s ease-in-out, box-shadow 0.3s ease-in-out;
}
.social-icon {
  display: inline-flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  margin-left: 5px;
  width: 60px;
  height:60px;
  border-radius: 10%;
  /* background-color: #ffffff; */
  color: #000000;
  margin-right: 5px; /* Adjust margin as needed */
}
.icon-container {
  display: flex;
  justify-content:center;
  gap: 15px;
}
.background-gradient {
  background: linear-gradient(to bottom right, #2F80ED, #56CCF2);
}

.custom-font-family {
  font-family: 'Arial', sans-serif; 
  font-weight: 800;
}
.soft-gradient-bg {
  background: linear-gradient(to bottom, #f0f9ff, #67b8ea);
}
.custom-card-size {
  height: 100%; /* Custom height */
  width: 90%; /* Full width of the parent container */
  margin-left: 5%;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}
.custom-card-size:hover {
  transform: scale(1.05); /* Slightly increase the scale of the card */
  box-shadow: 0 5px 15px rgba(0,0,0,0.3); /* Add more shadow to create depth */
}

.productContainer .card {
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
  transition: transform 0.3s ease;
}

.productContainer .card:hover {
  transform: scale(1.03);
  box-shadow: 0 5px 15px rgba(0,0,0,0.2);
}

.summary {
  height: 100%;
  width: 90%;
  margin-left: 5%;
  margin-top: 20px;
  padding: 20px;
  background: #f8f9fa;  
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
  align-items: center;
}


